import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import Description from "../components/description.js"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Experience = ({ data }) => {
  return (
    <Layout pageTitle="Experience" titleClass="mb-0">
      {/* <div className="row">
        <div className="col-12 mt-3 mb-5">
          <h2 className="section-title">Professional and Academic Experience</h2>
        </div>
      </div> */}
      <div className="row">
        {/* <section className="about-list col-12 col-md-6 col-lg-7 mb-4 mb-md-0"> */}
        <section className="about-list col-12 mb-4 mb-md-0">
          {/* <h2 className="about-list__header m-0">Jobs</h2> */}
          {data.jobs.edges.map(({ node }) => ( 
            <div className="experience-item mt-5" key={node.id}>
              <section className="experience-item__header">
                <h2 className="experience-item__header__employer">
                  <span className="experience-item__employer__name">{node.relationships.employer.name}</span><span>,&nbsp;</span>
                  <span className="experience-item__employer__location ml-0 mr-auto">{node.location.city}, {node.location.state}</span>
                </h2>
                {/* <h4 className="d-flex flex-row justify-content-between">
                  <span className="experience-item__dates ml-auto mr-0"> {node.start_date.month} {node.start_date.year} - {node.end_date.year !== -1 ? `${node.end_date.month} ${node.end_date.year}` : `Present`}</span>
                </h4> */}
                <h3 className="experience-item__header__position mb-0">
                  {node.positions.map((position, index) => (
                    <div key={index}>{position}</div>
                  ))}
                  {/* <p className="experience-item__header__position">{node.positions.join(`, `)}</p> */}
                </h3>
              </section>
              <div className="margin-fix" aria-hidden="true"></div>
              {/* {node.preamble !== null && <div className="bg-light mt-5 px-4 pt-3 pb-1" dangerouslySetInnerHTML={{ __html: node.preamble.html}}></div>} */}
              {node.preamble !== null && <Description className="content-preamble bg-light mt-4 px-4 pt-3 pb-1" html={node.preamble.html} />}
              {/* {node.description !== null && <div className="bg-light mt-5 px-4 pt-3 pb-1" dangerouslySetInnerHTML = {{ __html: node.description.html}}></div>} */}
              {node.description !== null && <Description className="content-description bg-light mt-4 px-4 pb-3" html={node.description.html} />}
            </div>
          ))}
        </section>
        {/* <section className="about-list col-12 col-md-5 offset-md-1 col-lg-4 mt-5 mt-md-0">
          <h3 className="about-list__header m-0"><FontAwesomeIcon className="fa-icon" icon={["fas", "graduation-cap"]} />&nbsp;Education</h3>
          {data.degrees.edges.map(({ node }) => (
            <div className="degree-item mt-5" key={node.id}>
              <h4 className="degree-item__degree-type">{node.degree_type}</h4>
              <h5 className="degree-item__institution-date">{node.relationships.institution.name}, {node.year_received}</h5>
              <h5 className="degree-item__location-city">{node.location.city}, {node.location.state}</h5>
            </div>
          ))}
        </section> */}
      </div>
    </Layout>
  )
}

export default Experience

export const query = graphql `
query {
  degrees: allNodeEducation (
      sort: { order: DESC, fields: field_sorting_weight }
    ) {
      edges {
        node {
          id
          degree_type: field_edu_degree
          relationships {
            institution: field_edu_institution {
              name
            }
          }
          year_received: field_edu_year_received
          location: field_location_city {
            city: location_city_city
            state: location_city_state
          }
        }
      }
    }
    jobs: allNodeExperience (
      sort: { order: DESC, fields: field_sorting_weight }
    ) {
      edges {
        node {
          id
          positions: field_exp_position_title
          relationships {
            employer: field_exp_employer {
              name
            }
          }
          location: field_location_city {
            city: location_city_city
            state: location_city_state
          }
          start_date: field_start_date {
            month: month_year_month
            year: month_year_year
          }
          end_date: field_end_date {
            month: month_year_month
            year: month_year_year
          }
          preamble: field_preamble_formatted {
            html: processed
          }
          description: field_description_formatted {
            html: processed
          }
        }
      }
    }
  }
`